@import '../../style/themes/index';
@import '../../style/mixins/index';

@divider-prefix-cls: ~'@{ant-prefix}-divider';
@divider-text-padding: 1em;

.@{divider-prefix-cls} {
  .reset-component;

  background: @border-color-split;

  &,  /* for compatiable */
  &-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    width: 1px;
    height: 0.9em;
    margin: 0 8px;
    vertical-align: middle;
  }

  &-horizontal {
    display: block;
    clear: both;
    width: 100%;
    min-width: 100%; // Fix https://github.com/ant-design/ant-design/issues/10914
    height: 1px;
    margin: 24px 0;
  }

  &-horizontal&-with-text-center,
  &-horizontal&-with-text-left,
  &-horizontal&-with-text-right {
    display: table;
    margin: 16px 0;
    color: @heading-color;
    font-weight: 500;
    font-size: @font-size-lg;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    &::before,
    &::after {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 50%;
      border-top: 1px solid @border-color-split;
      transform: translateY(50%);
      content: '';
    }
  }

  &-horizontal&-with-text-left,
  &-horizontal&-with-text-right {
    .@{divider-prefix-cls}-inner-text {
      display: inline-block;
      padding: 0 @divider-text-padding;
    }
  }

  &-horizontal&-with-text-left {
    &::before {
      top: 50%;
      width: 5%;
    }
    &::after {
      top: 50%;
      width: 95%;
    }
  }

  &-horizontal&-with-text-right {
    &::before {
      top: 50%;
      width: 95%;
    }
    &::after {
      top: 50%;
      width: 5%;
    }
  }

  &-inner-text {
    display: inline-block;
    padding: 0 @divider-text-padding;
  }

  &-dashed {
    background: none;
    border-color: @border-color-split;
    border-style: dashed;
    border-width: 1px 0 0;
  }

  &-horizontal&-with-text-center&-dashed,
  &-horizontal&-with-text-left&-dashed,
  &-horizontal&-with-text-right&-dashed {
    border-top: 0;
    &::before,
    &::after {
      border-style: dashed none none;
    }
  }

  &-vertical&-dashed {
    border-width: 0 0 0 1px;
  }
}

@primary-color: #2d2d20;@secondary-color: #c40f11;@text-color-primary: fade(@black, 65%);@info-color: #0d6bfe;@success-color: #12c800;@processing-color: red;@error-color: #ef0000;@warning-color: #ff7900;@normal-color: #d9d9d9;@layout-body-background: #f4f4f4;@table-primary-color: #e3e4e4;@font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@border-radius-base: 5px;@border-width-base: 2px;@height-base: 42px;@item-hover-bg: @layout-body-background;@layout-header-background: @layout-body-background;@menu-item-active-bg: transparent;@form-vertical-label-padding: 0 0 4px;@text-color: @primary-color;@heading-color: @primary-color;@input-color: @text-color-primary;@text-selection-bg: @highlight-color;@btn-font-weight: 600;@typography-title-font-weight: 800;@process-icon-color: @secondary-color;@steps-title-line-height: 1.8;@card-head-padding: 0;@card-actions-background: @white;@card-actions-li-margin: 8px 0;@select-selection-item-bg: @item-hover-bg;@dropdown-vertical-padding: 8px;@table-bg: unset;@table-header-bg: @table-primary-color;@table-header-color: @text-color;@table-header-sort-bg: @table-primary-color;@table-body-sort-bg: unset;@table-row-hover-bg: unset;@table-header-sort-active-bg: @table-primary-color;@table-header-filter-active-bg: @table-primary-color;@tabs-ink-bar-color: @secondary-color;@pagination-item-bg: unset;@pagination-item-size: 32px;@pagination-item-bg-active: @table-primary-color;@pagination-item-link-bg: unset;@menu-highlight-color: @primary-color;@tree-bg: unset;@tree-node-hover-bg: unset;@tree-node-selected-bg: unset;@statistic-title-font-size: 12px;@statistic-content-font-size: 16px;@select-item-selected-bg: @layout-body-background;@badge-height: 18px;